<template>
  <div class="system-layout">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "SystemLayout",
};
</script>

<style scoped>
.system-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
}
</style>
